import TrialBanner from "components/common/footer-banners/TrialBanner";
import HeroLayout5 from "components/common/hero/HeroLayout5";
import Highlights from "components/common/Highlights";
import EducationReviews from "components/landing-pages/sled/EducationReviews";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function SvppPage() {
  const renderContent = data => {
    const highlightSection = {
      title: "How Rhombus Can Help",
      image: data.highlightImage,
      list: [
        {
          description:
            "Explore the potential of SVPP funding with Rhombus as your trusted partner.",
        },
        {
          description:
            "Experience the unparalleled benefits of superior cloud-based security solution tailored to the unique needs of schools, enforcement, and government agencies",
        },
        {
          description:
            "Elevate your security with a unified platform of smart cameras, sensors, and integrations",
        },
      ],
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>School Violence Prevention Program </title>
          <meta
            name="description"
            content="With up to $73 million in available funding, this program offers a maximum of $500,000 per awardee in federal funding to enhance school safety measures."
          />
          <meta
            property="og:image"
            content="https://rhombus.com/img/META-svpp.png"
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout5
          titleSmall={true}
          formTitle="Contact us for SVPP grant support"
          formId="7d5416f2-5070-4df9-a6d5-bea9d2f0f383"
          pretTitle="ENHANCING SCHOOL SAFETY"
          title="School Violence Prevention Program and Rhombus Cloud-based Physical Security"
          blurb="The US Department of Justice's Community Oriented Policing Services unveiled the FY24 School Violence Prevention Program (SVPP), offering $73 million for school safety enhancement. Each recipient can receive up to $500,000 in federal aid. To capitalize on SVPP funds, connect with Rhombus  to improve school safety and streamline security operations."
        />
        <Highlights data={highlightSection} center color="var(--white)" />
        <EducationReviews />
        <TrialBanner />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      highlightImage: file(
        relativePath: { eq: "components/landing-pages/img/svpp.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery render={renderContent} query={GET_IMAGES} />;
}
