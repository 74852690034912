import RhombusButton from "components/common/RhombusButton";
import RoundLink from "components/common/RoundLink";
import PageContent from "components/page/PageContent";
import { Title } from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "linaria";
import React from "react";

const contentContainer = css`
  height: 100%;
  background-color: var(--nuetral-100);
  display: flex;
  align-items: center;
  padding: 5rem 0;
  @media (max-width: 1024px) {
    height: 100%;
  }
`;

const innerContent = css`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const contentLeft = css`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 50%;
  flex-grow: 1;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const contentRight = css`
  width: 50%;
  flex-grow: 1;
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

const imageClass = css`
  max-height: 735px;
`;

const EducationReviews = () => {
  return (
    <section className={contentContainer}>
      <PageContent>
        <div className={innerContent}>
          <div className={contentLeft}>
            <Title>Education Leaders Love Using Rhombus</Title>
            <p>
              From easy management to proactive AI alerts, schools of all sizes
              are choosing Rhombus to protect students and staff.
            </p>
            <RhombusButton
              type="gradient"
              path="/case-studies/"
              title="View Customer Case Studies"
            />
          </div>
          <div className={contentRight}>
            <StaticImage
              src="./img/review-bubbles-min.png"
              alt="customer stories"
              className={imageClass}
              objectPosition="top"
              objectFit="contain"
            />
          </div>
        </div>
      </PageContent>
    </section>
  );
};

export default EducationReviews;
